import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  TemplateBackstageLogoIcon,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  CustomHomepageGrid,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { AlsenseBackstageLogo } from './AlsenseBackstageLogo';
import { PostsTableCard } from '@drodil/backstage-plugin-qeta';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 150,
  },
  path: {
    fill: '#E2000F',
  },
}));

const defaultConfig = [
  {
    component: 'HomePageSearchBar',
    x: 0,
    y: 5,
    width: 12,
    height: 5,
  },
  {
    component: 'HomePageStarredEntities',
    x: 0,
    y: 7,
    width: 6,
    height: 12,
  },
  {
    component: 'HomePageToolkit',
    x: 6,
    y: 7,
    width: 6,
    height: 12,
  },
  {
    component: 'HomePageTopVisited',
    x: 0,
    y: 12,
    width: 6,
    height: 20,
  },
  {
    component: 'HomePageRecentlyVisited',
    x: 6,
    y: 12,
    width: 6,
    height: 20,
  },
  {
    component: 'PostsTableCard',
    x: 0,
    y: 22,
    width: 12,
    height: 35,
  },
];

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<AlsenseBackstageLogo classes={{ svg, path }} />}
            />
          </Grid>
          <CustomHomepageGrid config={defaultConfig} rowHeight={10}>
            <HomePageCompanyLogo
              className={container}
              logo={<AlsenseBackstageLogo classes={{ svg, path }} />}
            />
            <HomePageSearchBar
              classes={{ root: classes.searchBar }}
              placeholder="Search"
            />
            <HomePageStarredEntities />
            <HomePageToolkit
              tools={[
                {
                  url: 'https://backstage.alsenseplatform.com/docs/default/component/alsense-backstage/start/',
                  label: 'Backstage - Getting started',
                  icon: <TemplateBackstageLogoIcon />,
                },
                {
                  url: 'https://danfoss.visualstudio.com/Alsense',
                  label: 'Azure DevOps',
                  icon: <TemplateBackstageLogoIcon />,
                },
                {
                  url: 'https://www.figma.com/files/933408918843941231/team/962367968162085561/Alsense%E2%84%A2-IoT-Platform?fuid=1036914282010218420',
                  label: 'Figma',
                  icon: <TemplateBackstageLogoIcon />,
                },
                {
                  url: 'https://rancher.alsenseplatform.com',
                  label: 'Rancher',
                  icon: <TemplateBackstageLogoIcon />,
                },
                {
                  url: 'https://grafana.alsenseplatform.com',
                  label: 'Grafana',
                  icon: <TemplateBackstageLogoIcon />,
                },
                {
                  url: 'https://app.lokalise.com/projects',
                  label: 'Lokalise',
                  icon: <TemplateBackstageLogoIcon />,
                },
              ]}
            />
            <HomePageTopVisited />
            <HomePageRecentlyVisited />
            <PostsTableCard />
          </CustomHomepageGrid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
