import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { HomePageIFrameCard } from '@roadiehq/backstage-plugin-iframe';
import { Content, Page } from '@backstage/core-components';

export const CorootPage = () => {
  const configApi = useApi(configApiRef);
  const organizationName =
    configApi.getOptionalString('organization.name') ?? 'Backstage';

  const iframePropsCoroot = {
    title: `${organizationName} Root Cause Analysis - User: viewer@danfoss.com PW: DanViewer!428`,
    src: 'https://coroot.alsenseplatform.com',
    height: '1150px',
  };

  return (
    <Page themeId="home">
      <Content>
        <HomePageIFrameCard {...iframePropsCoroot} />
      </Content>
    </Page>
  );
};
