import { createApiRef } from '@backstage/core-plugin-api';

import { CompoundEntityRef } from '@backstage/catalog-model';
import {
  WorkflowMetrics,
  Labels,
  States,
  Workflow,
  TestExecutions,
  WorkflowList,
  TestExecution,
  TestWorkflowExecution,
} from './types';

/**
 * {@link @backstage/core-plugin-api#ApiRef} for the {@link TestkubeApi}
 *
 * @public
 */
export const testkubeApiRef = createApiRef<TestkubeApi>({
  id: 'plugin.testkube.service',
});

/**
 * API client interface for the Testkube plugin
 *
 * @public
 */
export interface TestkubeApi {
  // https://testkube.dev-dis-cooling.net/results/v1/tests/graphing-api-test
  getTest: (testId: string) => Promise<Workflow>;
  // https://testkube.dev-dis-cooling.net/results/v1/test-with-executions?textSearch=api&pageSize=20&page=0&selector=executor%3Dpostman-executor&status=passed
  getTests: (
    pageSize: number,
    page: number,
    textSearch?: string,
    selector?: string,
    status?: States,
  ) => Promise<WorkflowList>;
  getTestsForEntity: (entity: CompoundEntityRef) => Promise<WorkflowList>;
  // https://testkube.dev-dis-cooling.net/results/v1/labels
  getLabels: () => Promise<Labels>;
  // https://testkube.dev-dis-cooling.net/results/v1/tests/gcm-generic-suction-group-sm800-uat-test/executions?last=7&pageSize=1000&textSearch=gcm&status=failed
  getTestExecutions: (
    testId: string,
    last: number,
    pageSize: number,
    textSearch?: string,
    status?: States,
  ) => Promise<TestExecutions>;
  // https://testkube.dev-dis-cooling.net/results/v1/tests/graphing-api-test/metrics?last=7&limit=1000
  getTestMetrics: (
    testId: string,
    last: number,
    limit: number,
  ) => Promise<WorkflowMetrics>;
  // https://testkube.dev-dis-cooling.net/results/v1/executions/663a24309942e93b8a94024a
  getExecution: (executionId: string) => Promise<TestWorkflowExecution>;
  // https://testkube.dev-dis-cooling.net/results/v1/executions/663a24309942e93b8a94024a/logs returns content of type text/plain
  getExecutionLogs: (executionId: string) => Promise<string>;
  // https://testkube.dev-dis-cooling.net/results/v1/tests/e2e-test.dev.users.users-favourites/executions
  // Body: {"namespace":"testkube","runningContext":{"type":"user-ui"}}
  runTest: (testId: string, namespace: string) => Promise<TestExecution>;
  // https://<your-testkube-api-host>/test-workflow-executions/{executionID}/abort
  abortExecution: (executionId: string) => Promise<any>;
}
