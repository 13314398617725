import { TestkubeApi } from './TestkubeApi';
import {
  Labels,
  States,
  Workflow,
  TestExecution,
  TestExecutions,
  WorkflowList,
  WorkflowMetrics,
  TestWorkflowExecution,
} from './types';
import { CompoundEntityRef } from '@backstage/catalog-model';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export class TestkubeClient implements TestkubeApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }
  getTest(testId: string): Promise<Workflow> {
    return this.api(`/test-workflow/${testId}`);
  }

  getTests(
    pageSize: number,
    page: number,
    textSearch?: string,
    selector?: string,
    status?: States,
  ): Promise<WorkflowList> {
    let url = `/test-workflow-with-executions?pageSize=${pageSize}&page=${page}`;
    if (textSearch) {
      url += `&textSearch=${textSearch}`;
    }
    if (selector) {
      url += `&selector=${selector}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    return this.api(url);
  }

  getTestsForEntity(entity: CompoundEntityRef): Promise<WorkflowList> {
    throw new Error(`Method not implemented. For Entity: ${entity}`);
  }

  runTest(testId: string, namespace: string): Promise<TestExecution> {
    return this.api(`/test-workflows/${testId}/executions`, {
      method: 'POST',
      body: JSON.stringify({ namespace, runningContext: { type: 'user-ui' } }),
    });
  }

  getLabels(): Promise<Labels> {
    return this.api('/labels');
  }

  getTestExecutions(
    testId: string,
    last: number,
    pageSize: number,
    textSearch?: string,
    status?: States,
  ): Promise<TestExecutions> {
    let url = `/test-workflows/${testId}/executions?last=${last}&pageSize=${pageSize}`;

    if (textSearch) {
      url += `&textSearch=${textSearch}`;
    }
    if (status) {
      url += `&status=${status}`;
    }

    return this.api(url);
  }

  getTestMetrics(
    testId: string,
    last: number,
    limit: number,
  ): Promise<WorkflowMetrics> {
    return this.api(
      `/test-workflows/${testId}/metrics?last=${last}&limit=${limit}`,
    );
  }
  getExecution(executionId: string): Promise<TestWorkflowExecution> {
    return this.api(`/test-workflow-executions/${executionId}`);
  }

  getExecutionLogs(executionId: string): Promise<string> {
    return this.api(`/test-workflow-executions/${executionId}/logs`, {
      headers: { 'content-type': 'text/plain' },
    });
  }

  abortExecution: (executionId: string) => Promise<any> = (
    executionId: string,
  ) => {
    return this.api(`/test-workflow-executions/${executionId}/abort`, {
      method: 'POST',
    });
  };

  private async api<T>(path: string, init?: RequestInit): Promise<T> {
    const url = await this.discoveryApi.getBaseUrl('proxy');
    const { token } = await this.identityApi.getCredentials();

    const headers: HeadersInit = new Headers(init?.headers);
    if (!headers.has('content-type'))
      headers.set('content-type', 'application/json');
    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }

    const request = new Request(`${url}/testkube${path}`, {
      ...init,
      headers,
    });

    return fetch(request).then(async response => {
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }

      // Check content-type of the response
      const contentType = response.headers.get('content-type');

      // Handle text responses (plain text, html, etc.)
      if (
        contentType &&
        (contentType.includes('text/plain') ||
          contentType.includes('text/html') ||
          contentType.includes('text/') ||
          headers.get('content-type') === 'text/plain')
      ) {
        return response.text() as unknown as Promise<T>;
      }

      // Default to JSON for everything else
      return response.json() as Promise<T>;
    });
  }
}
